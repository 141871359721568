(function() {

  window.Product || (window.Product = {});

  var json_editor;

  Product.init = function() {
    Product.initJsonEditor();
  }

  Product.initJsonEditor = function() {
    var container = document.getElementById('jsoneditor');
    var options = {
      mode: 'tree',
      modes: ['code', 'form', 'text', 'tree', 'view'], // allowed modes
      onError: function (err) {
        alert(err.toString());
      },
    };
    json_editor = new JSONEditor(container, options, {});

    $('#jsonEditor').on('show.bs.modal', function (event) {
      json_editor.set($('#config').data('config'));
    });
    $("#jsonEditor .save").click(function () {
      $('#jsonEditor').modal('hide');
      $('#config_detail_original').hide();
      $('#config').data('config', json_editor.get());
      $('#config_detail').data('json', json_editor.get());
      FieldFormatter.format_fields();
      $('#config_detail').show();
      Product.updateProduct(json_editor.get());
    });

  }

  Product.updateProduct = function(payload) {
    var jqxhr = $.post("/products/" + $("#product_id").val(), { data: payload, field: 'payload', _method:'put' }, function() {});
    jqxhr.fail(function(event) {
      alert(event.responseText);
    });
  }

}).call(this); 