(function() {

  window.Scorecards || (window.Scorecards = {});

  var json_editor;

  Scorecards.init = function() {
    Scorecards.initJsonEditor();
  }

  Scorecards.initJsonEditor = function() {
    var container = document.getElementById('jsoneditor');
    var options = {
      mode: 'tree',
      modes: ['code', 'form', 'text', 'tree', 'view'], // allowed modes
      onError: function (err) {
        alert(err.toString());
      },
    };
    json_editor = new JSONEditor(container, options, {});

    $('#jsonEditor').on('show.bs.modal', function (event) {
      cell = $($(event.relatedTarget));
      json_editor.set($(cell).data('payload'));
      modal = $(this);
      modal.data('caller', cell.attr('id'));
      modal.find('.modal-title').text(cell.attr('id').replace('xy-xy', ' :: '));
    });
    $("#jsonEditor .save").click(function () {
      $('#jsonEditor').modal('hide');
      modal = $('#jsonEditor');
      cell  = $($("#" + modal.data('caller')));
      cell.find('.payload_original').hide();
      cell.data('payload', json_editor.get());
      cell.find('.payload').data('json', json_editor.get());
      FieldFormatter.format_fields();
      cell.find('.payload').show();
      Scorecards.update(cell.data('score'), cell.data('column'), json_editor.get());
    });

  }

  Scorecards.update = function(score, column, payload) {
    var jqxhr = $.post("/scorecards/" + $("#scorecard_id").val(), { data: { score: score, column: column, payload: payload}, _method:'put' }, function() {});
    jqxhr.fail(function(event) {
      alert(event.responseText);
    });
  }

}).call(this); 