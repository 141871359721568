(function() {

  window.ApplicationSupporter || (window.ApplicationSupporter = {});

  ApplicationSupporter.init = function(controller_supporter) {
    // initialise any help js for our page elements
    initClasses = {};
    initClasses[controller_supporter] = true;
    $('[data-init]').each(function() {
      classes = ($(this).data("init")).split(',');
      for(k in classes) {
        initClasses[classes[k].trim()] = true;
      }
    });
    for(var className in initClasses) {
      initialiseSupportClasses(className);
    }

    // and, tooltips, popovers
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $('.collapse').collapse('hide');

    function initialiseSupportClasses(k) {
      if ((window[k] !== null) && (typeof(window[k]) === 'object') && (typeof(window[k].init) === "function")) {
        window[k].init();
      }
    }

  }

}).call(this);