(function() {

  window.Session || (window.Session = {});

  window.Session.loggedIn = function() {
  	return ( window._session["sessionExpires"] > (new Date()).getTime() );
  }

  window.Session.screenOrientation = function() {
  	return ( window.innerWidth >  window.innerHeight ? 'landscape' : 'portrait' );
  }

	window.Session.screenSize = function() {
  	return ( (window.innerWidth + window.innerHeight)<1060 ? 'small' : 'large' );
  }

}).call(this); 