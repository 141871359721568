(function() {

  window.VicobaMeetings || (window.VicobaMeetings = {});

  VicobaMeetings.init = function() {
    if ($('#meeting-closed').val()!='closed') {

      $('.fa-check-square-o, .fa-frown-o').css('cursor', 'pointer');

      $('.fa-check-square-o, .fa-frown-o').click(function() {

        participantId = $(this).data('participant-id');
        attending     = $(this).hasClass('text-muted');
        apology       = $(this).data('apology');

        if (attending) {
          $( ( apology ? '' : '.fa-check-square-o' ) + '[data-participant-id="' + participantId + '"]').removeClass('text-muted');
          $( ( apology ? '' : '.fa-check-square-o' ) + '[data-participant-id="' + participantId + '"]').addClass('text-success');
        } else {
          $( '[data-participant-id="' + participantId + '"]').removeClass('text-success');
          $( '[data-participant-id="' + participantId + '"]').addClass('text-muted');
        }

        updateAttendance( participantId, attending, apology );
      });

      updateAttendance = function(participantId, attending, apology) {
        var jqxhr = $.post("/vicoba_meeting_participants/" + participantId, { participant_id: participantId, attending: attending, apology: apology, _method:'put' }, function() {});
        jqxhr.fail(function(event) {
          alert(event.responseText);
        });
      }
    }
  }

}).call(this); 