(function () {

  window.FieldFormatter || (window.FieldFormatter = {});

  FieldFormatter.format_fields = function () {
    $('.time, [data-time], [data-time-format]').each(function (index, e) {
      t = $(e);
      f = t.data('time-format');
      f = (f ? f : (t.hasClass("time") ? "dd/mm/yy HH:MM" : "ddd mmm dd yyyy HH:MM:ss"));
      v = t.data('time') || t.html();
      if (v === '') {
        t.html('');
      } else {
        if (isNaN(v)) {
          d = Date.parse(v.replace(' ', 'T').replace(' UTC', 'Z'));
          if (!isNaN(d)) {
            v = d.valueOf();
          }
        }
        if (!isNaN(v)) {
          v = (v < 14538528000 ? v * 1000 : v);
          if (f === "ago") {
            t.html(timeAgo(v));
          } else {
            partnerOffset = window._session.offset
            bt = t.data('browser-time');
            if (bt || partnerOffset == undefined) {
              v = v - ((new Date()).getTimezoneOffset() * 60000);
            }
            else {
              v = v - (-(partnerOffset / 60) * 60000);
            }
            t.html((new Date(Number(v))).format(f, true));
          }
        }
      }
    });

    $('input.phone').each(function () {
      if (!window._prod) {
        if (this.value == "") {
          this.value = "0" + ("" + Date.now()).substr(-9);
        }
      }
    });

    $('input.msisdn').each(function () {
      if (!window._prod) {
        if (this.value == "") {
          this.value = "255" + ("" + Date.now()).substr(-9);
        }
      }
    });

    $('.accounting').each(function () {
      t = $(this);
      value = t.html();
      currency = t.data("currency");
      minimumFractionDigits = (window._session['country'] == 'KE' || window._session['country'] == 'MW' || window._session['country'] == 'TZ' ? 0 : 2);
      maximumFractionDigits = (window._session['country'] == 'KE' || window._session['country'] == 'MW' || window._session['country'] == 'TZ' ? 0 : 2);
      if (t.data("number-precision") !== undefined && t.data("number-precision") !== "") {
        minimumFractionDigits = t.data("number-precision");
        maximumFractionDigits = t.data("number-precision");
      }
      if ((!isNaN(value)) && (value !== '')) {
        n = Number(parseInt(value) / 100.0);
        if (currency) {
          value = n.toLocaleString("en-US", { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits, minimumIntegerDigits: 1, useGrouping: true, style: 'currency', currency: currency });
          value = postFormatCurrency(value)
        } else {
          value = n.toLocaleString("en-US", { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits, minimumIntegerDigits: 1, useGrouping: true });
        }
      }
      $('table tr:last-child .accounting').each(function () {
        $td = $(this)
        $td.closest('table').find('th').eq($td.index()).css('text-align', 'right');
      });
      $('td.accounting').css('text-align', 'right');
      $(this).html(value).removeClass('accounting');
    });

    $('.number, [data-number-precision], [data-currency], [data-number-locale], [data-number-minimum-integer-digits], [data-number-grouping-symbol]').each(function (index, element) {
      e = $(element);
      value = e.html();
      v = "";
      if (value != "") {
        if (isNaN(value)) {
          v = value;
        } else {
          minimumFractionDigits = (e.data("currency") ? 2 : 0);
          maximumFractionDigits = (e.data("currency") ? 2 : 3);
          if (e.data("number-precision") !== undefined) {
            minimumFractionDigits = e.data("number-precision");
            maximumFractionDigits = e.data("number-precision");
          }
          minimumIntegerDigits = e.data("number-minimum-integer-digits") || 1;
          currency = e.data("currency");
          locale = e.data("number-locale") || "en-ZA";
          useGrouping = !(e.data("number-grouping-symbol") === false);
          groupingSymbol = e.data("number-grouping-symbol") || " ";
          style = (e.data("currency") ? "currency" : "decimal");

          v = postFormatCurrency(Number(value).toLocaleString(locale, { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits, minimumIntegerDigits: minimumIntegerDigits, useGrouping: useGrouping, currency: currency, style: style }));

          if (locale == "en-ZA") {
            v = v.replace(/,/g, ".");
          }
          if (useGrouping && groupingSymbol != " ") {
            v = v.replace(/\s/g, groupingSymbol);
          }
        }
      }
      $('table tr:last-child .number').each(function () {
        $td = $(this)
        $td.closest('table').find('th').eq($td.index()).css('text-align', 'right');
      });
      $('td.number').css('text-align', 'right');
      $(this).html(value).removeClass('number');
      e.html(v);
    });

    $('.show a').each(function () {
      t = $(this);
      if ((!t.hasClass('fa')) && (t.text() === '')) {
        $(this).html("show");
      }
    });

    $('.titleize').each(function () {
      t = $(this).html();
      t = t.replace(/_/g, ' ').trim();
      $(this).html(titleCaps(t));
    });

    $('.sentence').each(function () {
      t = $(this).html();
      t = t.replace(/_/g, ' ').trim();
      t = t + (t.charAt(t.length - 1) == '.' ? '' : '.');
      t = t.charAt(0).toUpperCase() + t.slice(1);
      $(this).html(t);
    });

    $('.msisdn').each(function () {
      t = $(this).html();
      if (t.substring(0, 2) == '25') {
        t = t.substring(0, 3) + nonBreakingSpace() + t.substring(3, 6) + nonBreakingSpace() + t.substring(6, 9) + nonBreakingSpace() + t.substring(9);
      } else {
        firstGrouping = ((['20', '27', '55', '62', '86', '91', '92'].indexOf(t.substring(0, 2)) == -1) ? 3 : 2);
        t = t.substring(0, firstGrouping) + nonBreakingSpace() + t.substring(firstGrouping, firstGrouping + 2) + nonBreakingSpace() + t.substring(firstGrouping + 2, firstGrouping + 5) + nonBreakingSpace() + t.substring(firstGrouping + 5);
      }
      $(this).html(t);
    });

    $('.telephone').each(function () {
      t = $(this).html();
      if (t.substring(0, 2) == '25') {
        t = '0' + t.substring(3, 6) + nonBreakingSpace() + t.substring(6, 9) + nonBreakingSpace() + t.substring(9);
      } else {
        firstGrouping = ((['20', '27', '55', '62', '86', '91', '92'].indexOf(t.substring(0, 2)) == -1) ? 3 : 2);
        t = '0' + t.substring(firstGrouping, firstGrouping + 2) + nonBreakingSpace() + t.substring(firstGrouping + 2, firstGrouping + 5) + nonBreakingSpace() + t.substring(firstGrouping + 5);
      }
      $(this).html(t);
    });

    $('[data-json]').each(function () {
      t = $(this);
      $(this).html(syntaxHighlightJson(t.data("json")));
    });

    $('.ansi').each(function () {
      $(this).html(ansi_up.ansi_to_html($(this).html(), { use_classes: true }));
    });
  };

  function nonBreakingSpace() {
    return String.fromCharCode(160);
  };

  function postFormatCurrency(value) {
    return value.replace(/(\D)(\d)/, "$1" + nonBreakingSpace() + "$2").replace(/\.\s/, '.');
  };

  function currencyToSymbol(currency) {
    var mappings = {
      GHC: '&#8373;',
      NGN: '&#8358;',
    };
    return (mappings[currency] || currency);
  };

  function syntaxHighlightJson(source) {
    json = JSON.stringify(source, undefined, 2);
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'text-success';
        } else {
          cls = '';
        }
      } else if (/true|false/.test(match)) {
        cls = 'text-warning';
      } else if (/null/.test(match)) {
        cls = 'text-info';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  };

  function timeAgo(v) {
    result = "right now";
    target_time = (v < 14538528000 ? v * 1000 : v);
    current_time = (new Date()).getTime();
    difference = current_time - target_time;
    ratio = 0;
    age = "";
    in_time = "";
    if (difference < 0) {
      in_time = "in ";
      result = " time";
      difference = difference * -1;
    } else {
      result = " ago";
    }
    if (difference > 60 * 60 * 24 * 334 * 1000) {
      ratio = (difference / (60 * 60 * 24 * 365 * 1000.0));
      age = "year";
    } else if (difference > 60 * 60 * 24 * 30 * 1000) {
      ratio = (difference / (60 * 60 * 24 * 31.5 * 1000.0));
      age = "month";
    } else if (difference > 60 * 60 * 24 * 6 * 1000) {
      ratio = (difference / (60 * 60 * 24 * 7 * 1000.0));
      age = "week";
    } else if (difference > 60 * 60 * 22 * 1000) {
      ratio = (difference / (60 * 60 * 24 * 1000.0));
      age = "day";
    } else if (difference > 60 * 52 * 1000) {
      ratio = (difference / (60 * 60 * 1000.0));
      age = "hour";
    } else if (difference > 60 * 38 * 1000) {
      age = "three quarters of an hour";
    } else if (difference > 60 * 22 * 1000) {
      age = "half an hour";
    } else if (difference > 60 * 17 * 1000) {
      age = "twenty minutes";
    } else if (difference > 60 * 8 * 1000) {
      age = "ten minutes";
    } else if (difference > 60 * 12 * 1000) {
      age = "a quarter of an hour";
    } else if (difference > 52 * 1000) {
      ratio = (difference / (60 * 1000.0));
      age = "minute";
    } else {
      age = "less than a minute";
    }
    if (ratio != 0) {
      ratio = Math.round(ratio);
      result = in_time + (ratio) + " " + age + (ratio === 1 ? " " : "s ") + result;
    } else {
      result = in_time + age + " " + result;
    }
    return result;
  };

}).call(this);