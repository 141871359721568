(function() {

  window.Stats || (window.Stats = {});

  metrics = '';

  window.Stats.init = function() {
    var is_root = location.pathname == "/";

    set_metrics();
    if (is_root) {
        setTimeout(get_metrics,500);
    }
  };

  get_metrics = function() {
    var jqxhr = $.getJSON('/api/statistics?metrics=' + metrics, function(data) {
      generated_at = null;
      for (var key in data) {
        if (key=="generated_at") {
          generated_at = data[key];
        } else {
          if (data[key]["warning"]) {
            $(".stat-" + key + " .card").addClass('card-inverse card-danger');
          } else {
            $(".stat-" + key + " .card").removeClass('card-inverse card-danger');
          }
          $(".stat-" + key + " .stat-value").html(data[key]["stat"]);
          $(".stat-" + key + " .stat-description").html(data[key]["description"]);
          $(".stat-" + key + " .stat-unit").html(data[key]["unit"]);
        }
      }
      d = Date.parse(generated_at.replace(' ','T').replace(' UTC', 'Z'));
      $(".stat-block[title]").each(function() {
        v = d.valueOf() - ((new Date()).getTimezoneOffset() * 60000);
        $(this).attr('title', (new Date(Number(v))).format("ddd mmm dd yyyy HH:MM:ss", true) );
      });
    }).fail(function(event) {
      //alert(event.responseText);
    }).always(function() {
      setTimeout(get_metrics,60000);
    });
  }

  set_metrics = function() {
    $("[data-metric]").each(function() {
      if (metrics.length>0) {
        metrics = metrics + ",";
      }
      metrics = metrics + $(this).data('metric');
      if ($(this).data('url')) {
        $(this).hover(function() {
          $(this).css('cursor','pointer');
        }).click(function() {
          window.location = $(this).data('url');
        });
      }
    });
  }

}).call(this); 