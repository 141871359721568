(function() {

  window.Uploads || (window.Uploads = {});

  Uploads.init = function() {
		$(".action").click(function() {
	  	window.location.href = buildQueryString() + '&' + 'perform=' + $(this).attr('id');
	  });

	  buildQueryString = function() {
	  	qs = '?_seperator=' + $(".column_seperator .active input").attr('id')
			$(".converter").each(function() {
				qs = qs + '&' + $(this).attr('id') + '=' + encodeURIComponent($(this).val());
		  });
		  qs = qs + '&link=' + $("#link").prop("checked") + "&target=" + $('#target').val();
		  return qs;
	  }

	  $('#target').keyup(function () {
	  	$('#db-import').prop('disabled', (($(this).val().length)<4));
	  });
  }

}).call(this); 