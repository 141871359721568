(function() {

  window.Pagination || (window.Pagination = {});

  Pagination.init = function() {
    $('.pagination .page-link').click(function() {
      window.location.search = YabxUtils.updateQueryStringParameter(window.location.search, 'page', $(this).data("page"));
    }); 
  }

}).call(this); 