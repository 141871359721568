(function() {

  window.InlineEdit || (window.InlineEdit = {});

  InlineEdit.init = function() {
    $(".inline-edit").each(function() {
      InlineEdit.initContainer($(this));
    });
  }

  InlineEdit.val = function(element) {
    text = $(element).text();
    if (text==InlineEdit.getPlaceHolderText($(element))) {
      text = "";
    }
    return text;
  }

  InlineEdit.initElement = function(element) {
    InlineEdit.initContainer($(element));
  }

  InlineEdit.initContainer = function(container) {
    container.css("cursor","pointer");
    if (container.html().length==0) {
      container.text(InlineEdit.getPlaceHolderText(container));
      container.addClass("text-muted");
    }
    container.click(function() {
      container = $(this);
      container.removeClass("text-muted");
      container.off('click');
      pattern = container.data("pattern");
      if (pattern===undefined) {
        pattern = "";
      } else {
        pattern = " pattern='" + pattern + "' ";
      }
      required = container.data("required");
      placeholder = InlineEdit.getPlaceHolderText(container);
      text = container.text();
      if (text==placeholder) {
        text = "";
      }
      container.data("oldText", text);
      container.html("<input type='text' value='" + text + "' placeholder='" + placeholder + "'" + pattern + (required===undefined ? '' : 'required') + ">");
      container.find("input").keypress(function(event) {
        if (event.which == 13) {
          event.preventDefault();
          if (container.find("input")[0].checkValidity()) {
            oldText = container.data('oldText');
            newText = container.find("input").val();
            container.text(newText);
            InlineEdit.initContainer(container);
            if (oldText!=newText) {
              container.trigger('submit');
            }
          }
        }
      }).blur(function(event) {
          event.preventDefault();
          container.text(container.data("oldText"));
          InlineEdit.initContainer(container);
      }).keydown(function(event) {
          if (event.keyCode == 27) {
            event.preventDefault();
            container.text(container.data("oldText"));
            InlineEdit.initContainer(container);
          }
      }).focus();
    });
  }

  InlineEdit.getPlaceHolderText = function(container) {
    placeholder = container.data('placeholder');
    if (placeholder===undefined) {
      placeholder = 'edit';
    }
    return placeholder;
  }

}).call(this); 