(function() {

  window.CodeEditor || (window.CodeEditor = {});

  CodeEditor.init = function() {
    $(".code-editor").each(function(index, e) {
      var editor_div = $(e);
      var id     = editor_div.attr('id') || "editor_div_".concat(index);
      editor_div.attr('id', id);
      var RubyMode = window.ace.require("ace/mode/html_ruby").Mode;
      var editor = window.ace.edit(id);
      editor.setTheme("ace/theme/solarized_light");
      editor.session.setMode(new RubyMode());
    });
  }

}).call(this); 