(function () {

    window.vicobaLoanRequests || (window.vicobaLoanRequests = {});

    vicobaLoanRequests.init = function() {
        $(".filters input[name=vicoba_name], .filters input[name=vicoba_loan_status]").click(function() {
            window.location.search = YabxUtils.updateQueryStringParameter(window.location.search, $(this).attr("name"), $(this).val());
        });
    };

}).call(this);