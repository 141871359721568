(function() {

  window.YabxUtils || (window.YabxUtils = {});
  
  YabxUtils.updateQueryStringParameter = function(uri, key, value) {
    regex     = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(regex)) {
      return uri.replace(regex, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  YabxUtils.screenHeight = function() {
    return $(window).height();
  }

}).call(this); 