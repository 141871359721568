(function() {

  window.Instruments || (window.Instruments = {});

  Instruments.init = function() {
    $(".status").click(function() {
      window.location.search = YabxUtils.updateQueryStringParameter(window.location.search, $(this).data('status'), ($(this).hasClass('on') ? 'off' : 'on' ));
    });

    $(".filters input[name=status_group]").click(function() {
        window.location.search = YabxUtils.updateQueryStringParameter(window.location.search, 'status_group', $(this).val());
    });

    $(".filters .tranche, .filters .other").click(function() {
      window.location.search = YabxUtils.updateQueryStringParameter(
          window.location.search, $(this).val(), ($(this).is(':checked') ? 'on' : 'off' )
      );
    });

    $(".date_type").change(function() {
      window.location.search = YabxUtils.updateQueryStringParameter(window.location.search, 'date_type', $(this).val() );
    });
  }

}).call(this); 