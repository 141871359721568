(function() {

  window.Demo || (window.Demo = {});

  Demo.init = function() {
    $(".demo-card").click(function (e) {
      target = $(e.target).closest('.demo-card').data('target');
      domain = 'https://yabx-in.yabx.co' + ':' + window.location['port'];
      window.open(domain + "/demo_login?partner=" + window.location['hostname'].split('.')[0] + '&target=' + target + '&brand=' + window.location['hostname'].split('.')[1]);
    });
  }

}).call(this); 
