(function() {

  window.LayoutFormatter || (window.LayoutFormatter = {});

  LayoutFormatter.init = function() {
    if ($(".requires-wide").length>0) {
      $('.page-body').removeClass('container').addClass('container-fluid');
    }
    LayoutFormatter.formatTables();
    Pagination.init();
    DatePicker.init();
  }

  LayoutFormatter.formatTables = function() {
    // de-stripe
    $(".table-not-striped").each(function() {
      $(this).addClass("table table-bordered table-hover table-sm non-standard-table");
    });
    tables = $("table:not(.non-standard-table)").filter(function() {return $(this).parents('div.non-standard-table').length < 1;});
    tables.each(function() {
      tbl = $(this)
      // make table responsive
      tbl.addClass("table table-striped table-bordered table-hover table-sm").wrap( "<div class='table-responsive'></div>" );
      // assume last column has clicks, if so, make it hide on smaller screens
      if (tbl.find('tr:last-child td:last-child a').length>0) {
        // flag the column to be hidden
        tbl.find('th:last-child').addClass('hidden-md-down');
        // add the click
        tbl.find('tr').each(function() {
          $(this).find("td:not(:has(a))").click(function() {
            window.location.href = $($(this).closest('tr').find('td:last-child a')[0]).attr('href');
          }).hover(function() {
            $(this).css('cursor','pointer');
          });
        });
      }
      // hide cells in columns when neccessary
      tbl.find("[class*='hidden']").each(function() {
        classes = $(this).attr('class').split(/\s+/);
        for (var i = 0; i < classes.length; i++) {
          if (classes[i].startsWith('hidden')) {
            col = $(this).parent().children().index($(this)) + 1;
            tbl.find('td:nth-child(' + col + ')').addClass(classes[i]);
          }
        }
      });
    });
  }

}).call(this); 