(function() {

  window.Handsets || (window.Handsets = {});

  Handsets.init = function() {
    initMsisdnUpdate();
    initImpair();
    initEnable();
    initEnableAll();
  }

  initMsisdnUpdate = function() {
    $("#handset_msisdn").blur(function() {
      update($(this).val().trim(), 'msisdn');
    });
  }

  initImpair = function() {
    $(".enabled_feature").click(function(e) {
      feature = $(this).data('feature');
      $('.impaired_feature.' + feature).show().prop('disabled', true);
      $(this).hide();
      update(feature, 'impair');
      e.preventDefault();
      return false;
    });
  }

  initEnable = function() {
    $(".impaired_feature").click(function(e) {
      feature = $(this).data('feature');
      $('.enabled_feature.' + feature).show().prop('disabled', true);
      $(this).hide();
      update(feature, 'enable');
      e.preventDefault();
      return false;
    });
  }

  initEnableAll = function() {
    $(".enable_all").click(function(e) {
      $(this).prop('disabled', true);
      $('.enabled_feature').show().prop('disabled', true);
      $('.impaired_feature').hide().prop('disabled', true);
      update('enable_all', 'enable_all');
      e.preventDefault();
      return false;
    });
  }

  update = function(payload, field) {
    var jqxhr = $.post(window.location.pathname, { data: payload, field: field, _method:'put' }, function() {});
    jqxhr.fail(function(event) {
      alert(event.responseText);
    });
  }

}).call(this); 