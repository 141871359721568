$(document).ready(function () {
  function toggleIcon(e) {
    $(e.target)
      .prev('.panel-heading')
      .find('.more-less')
      .toggleClass('glyphicon-plus glyphicon-minus');
  }
  $('.panel-group').on('hidden.bs.collapse', toggleIcon);
  $('.panel-group').on('shown.bs.collapse', toggleIcon);

  var teams_ori = '';
  $('.choose-partner #user_partner').on('change', function () {
    $('.new_user_form_fields').hide();
    $('.team-roles-listing').html('');
    if ($(this).val() != '') {
      teams = $('#user_team_ids').html();
      if (teams_ori == '') {
        teams_ori = teams;
      }
      partner = $('option:selected', this).attr('code');
      options = $(teams_ori)
        .filter("optgroup[label='" + partner + "']")
        .html();
      if (options) {
        $('#user_team_ids').html(options);
      } else {
        $('#user_team_ids').empty();
      }
      $('.new_user_form').removeClass('display-none');
    } else {
      $('.new_user_form').addClass('display-none');
    }
  });

  $('.select_team').on('change', function () {
    if ($(this).val() != '') {
      var user_id = $('.user_id_info').attr('user_id');
      $('.new_user_form_fields').show();
      $.ajax({
        url: '/fetch_team_roles',
        type: 'GET',
        data: { team_ids: $(this).val(), user_id: user_id }
      });
    } else {
      $('.new_user_form_fields').hide();
      $('.team-roles-listing').html('');
    }
  });

  if ($('.select_team').val() != '') {
    $('.select_team').trigger('change');
    $('.new_user_form_fields').show();
  }

  $('.submit-create-user').on('click', function () {
    if ($('.select_team').val() != '') {
      if ($('.larger-checkbox')[0]) {
        if ($('.larger-checkbox:checked').length == 0) {
          alert('Please select at-least one role');
          return false;
        }
      }
    }
    else
    {
      alert('Please select team.')
    }
  });
});
