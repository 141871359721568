(function() {

  window.Charts || (window.Charts = {});

  Charts.init = function() {
    drawSparklines();
    drawBarGraph();
  }

  Charts.getTimeFormat = function(period) {
    format = "%e %B";
    if (period=='year') {
      format = "YY";
    } else if (period=='hour') {
      format = "%e %b %H:%M";
    } else if (period=='m15') {
      format = "%e %b %H:%M";
    } else if (period=='m10') {
      format = "%e %b %H:%M";
    } else if (period=='m5') {
      format = "%e %b %H:%M";
    }
    return format;
  }

  drawBarGraph = function() {

    $(".bargraph").each(function(index, e) {

      container = $(this);

      series      = container.data('series');
      period      = container.data('period');
      from        = container.data('from');
      to          = container.data('to');
      accounting  = (container.data('accounting')=='true');

      var url     = '/api/timeseries/' + series + '/' + period + '/' + from + '/' + to;

      var width   = container.width();
      var height  = container.height();

      var x      = d3.scaleTime().range([0, width]);
      var y      = d3.scaleLinear().range([height, 0]);
      var x_bar  = d3.scaleBand().range([0, width]).padding(0.1);

      var formatTime = d3.timeFormat(Charts.getTimeFormat(period));

      var div = d3.select(container.get(0)).append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("left", (width-57) + "px")
        .style("top",  "2px");

      var svg = d3.select(container.get(0)).append("svg")
                                        .attr("width", width)
                                        .attr("height", height)
                                      .append("g")  ;


      d3.csv(url, function(error, data) {
        if (error) throw error;

        data.forEach(function(d) {
            d.time = new Date(d.time*1000);
            d.value = +(d.value/(accounting ? 100.0 : 1));
        });

        var max_value = (d3.max(data, function(d) { return d.value; }));
        var min_value = (d3.min(data, function(d) { return d.value; }));

        // Scale the range of the data
        x.domain(d3.extent(data, function(d) { return d.time; }));
        y.domain([0, max_value]);
        x_bar.domain(data.map(function(d) { return d.time; }));

        svg.selectAll(".bar")
            .data(data)
          .enter().append("rect")
            .attr("class", "bar")
            .attr("x", function(d) { return x_bar(d.time); })
            .attr("width", x_bar.bandwidth())
            .attr("y", function(d) { return y(d.value); })
            .attr("height", function(d) { return height - y(d.value); })
            .on("mouseover", function(d) {
                d3.selectAll(".bargraph .highlighted").classed("highlighted", false).classed("bar", true);
                d3.select(this).classed("highlighted", true).classed("bar", false);
                div.transition()
                  .duration(200)
                  .style("opacity", .9);
                div.html(
                  formatTime(d.time) +
                  "<br/>"  + d.value);
            })
           .on("mouseout", function(d) {
                d3.select(this).classed("highlighted", false).classed("bar", true);
                div.transition()
                  .duration(200)
                  .style("opacity", 0);
            });


      });

    });

  }

  drawSparklines = function() {

    $(".sparkline").mouseover(function() {
      $(this).find('circle').css('opacity', 0.3);
    });

    $(".sparkline").mouseout(function() {
      $(this).find('circle').css('opacity', 0);
    });

    $(".sparkline").each(function(index, e) {

      container = $(this);

      series      = container.data('series');
      period      = container.data('period');
      from        = container.data('from');
      to          = container.data('to');
      accounting  = container.data('accounting');
      sql         = container.data('sql');

      var url     = '/api/timeseries/' + series + '/' + period + '/' + from + '/' + to + ( sql ? '?sql=5' : '' );

      var width   = container.width();
      var height  = container.height();

      var x = d3.scaleTime().range([0, width]);
      var y = d3.scaleLinear().range([height, 0]);

      var formatTime = d3.timeFormat(Charts.getTimeFormat(period));

      var div = d3.select(container.get(0)).append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("left", (width-57) + "px")
        .style("top",  "2px");

      var valueline = d3.line()
          .curve(d3.curveBasis)
          .x(function(d) { return x(d.time); })
          .y(function(d) { return y(d.value); });

      var svg = d3.select(container.get(0)).append("svg")
          .attr("width", width)
          .attr("height", height)
          .append("g");

      // Get the data
      d3.csv(url, function(error, data) {
        if (error) throw error;

        data.forEach(function(d) {
            d.time = new Date(d.time*1000);
            d.value = +(d.value/(accounting ? 100.0 : 1));
        });

        max_value = (d3.max(data, function(d) { return d.value; }));
        min_value = (d3.min(data, function(d) { return d.value; }));

        // Scale the range of the data
        x.domain(d3.extent(data, function(d) { return d.time; }));
        y.domain([0, max_value]);

        // Add the valueline path.
        svg.append("path")
            .data([data])
            .attr("class", "line")
            .attr("d", valueline);

        svg.selectAll("dot")
           .data(data)
         .enter().append("circle")
           .attr("class", "dot")
           .attr("r", 4)
           .attr("cx", function(d) { return x(d.time); })
           .attr("cy", function(d) { return y(d.value); })
           .on("mouseover", function(d) {
                d3.select(this).classed("dot-highlighted", true).classed("dot-not", false);
                div.transition()
                  .duration(200)
                  .style("opacity", .9);
                div.html(
                  formatTime(d.time) +
                  "<br/>"  + d.value);
            })
           .on("mouseout", function(d) {
                d3.select(this).classed("dot-highlighted", false).classed("dot-not", true);
                div.transition()
                  .duration(200)
                  .style("opacity", 0);
            });
            
      });

    });
  }

}).call(this); 