(function() {

  window.Notes || (window.Notes = {});

  Notes.init = function() {
    $("#notes-page").click(function() {
      $("#documentation").toggle();
    });
    $(".documentation-body").css('min-height', YabxUtils.screenHeight()/2);
    $("#documentation_modal .save-button").click(function() {
      $("#documentation_modal").modal('hide');
      $("#documentation .body-content").hide();
      $("#documentation .body-wait").show();
      var jqxhr = $.post("/documentation/" + $("#documentation_modal").data('controller') + '/' + $("#documentation_modal").data('action'), { data: $("#documentation_modal .documentation-body").val(), _method:'put' }, function(data) {
        $("#documentation .body-wait").hide();
        $("#documentation .body-content").show();
        $("#notes-page").css('color', 'rgb(54, 100, 176)')
        $("#documentation .body-content .container").html(data);
        $("#documentation .container").show();
        $("#documentation .no-doc").hide();
        $("#documentation button").html("Edit");
      });
      jqxhr.fail(function(event) {
        //alert(event.responseText);
      });
    });
  }

}).call(this); 