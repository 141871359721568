(function() {

  window.NavHandler || (window.NavHandler = {});

  NavHandler.init = function() {
    
    $(".dropdown-menu a:first-child").addClass('m-l-1');
    $(".app-block-footer .partners").click(function() {
      window.location.replace("/partners");
    });
    $("[data-nav-url]").click(function() {
      window.location.replace($(this).data('nav-url'));
    });
  	$("#logInOut").each(function() {
	  	if (Session.loggedIn()) {
	  		$(this).addClass("fa-sign-out").attr("title", "Sign Out").click(function() {
	  			window.location.replace("/logout");
	  		});
	  	} else {
	  		$(this).addClass("fa-sign-in").attr("title", "Sign In").click(function() {
	  			window.location.replace("/login");
	  		});
	  	}
  	});
    $(".order-by button").click(function() {
      search = window.location.search;
      search = YabxUtils.updateQueryStringParameter(search, 'order', $(this).data('order-by'));
      window.location.search = search;
    });
  }

}).call(this); 