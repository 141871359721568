(function() {
  
 window.Templater || (window.Templater = {});

 	// where source_template is the invisible source structure, the hash is the data to be used
 	// to populate the template (if any). And the mechanism (optional), if any, is a function that
 	// will populate the template, and return a populated template.
 	Templater.append = function(source_template, hash, mechanism) {
 		return Templater.populate(source_template, hash, mechanism).appendTo(source_template.parent()).show();
 	}

 	Templater.prepend = function(source_template, hash, mechanism) { 
 		return Templater.populate(source_template, hash, mechanism).prependTo(source_template.parent()).show();
 	}

 	Templater.insertAfter = function(append_to, source_template, hash, mechanism) { 
 		return Templater.populate(source_template, hash, mechanism).insertAfter(append_to).show();
 	}

	Templater.populate = function(source_template, hash, mechanism) {
		template = source_template.clone(true, true);
		template.removeClass("template").addClass("templated");
		// replace fields using data tags
		if (hash!=null) {
			template.find("[data-field]").addBack("[data-field]").each(function() {
				if (!((typeof hash[$(this).data("field")])==="object")) {
					if ($(this).is('input')) {
						$(this).attr('value', hash[$(this).data("field")]);
					} else if ($(this).is('select')) {
						if (typeof(hash[$(this).data("field")])!='undefined') {
							this.value = hash[$(this).data("field")];
						}
					} else {
						$(this).html(hash[$(this).data("field")]);
					}
				}
			});
			if (typeof(hash['id'])!='undefined') {
				template.attr('id', hash['id']);
			}
		}
		if (mechanism!=null) {
			template = mechanism(template);
		}
		return template;
	}

  Templater.populate_template = function(singular_element_name, data_hash, templater) {
    target = $('#' + singular_element_name + 's');
	  $.each(data_hash, function(name, value) {
		  template = $('#' + '_' + singular_element_name + name);
		  existing = (template.length!=0);
		  if (!existing) {
        template = $('#' + singular_element_name + '_template').clone(true).attr('id', '_' + singular_element_name + name);
		  }
		  template = templater(template, name, value);
		  if (!existing) {
        template.appendTo(target).show();
		  } 
    });
  }
 
}).call(this);