(function() {

  window.RenderJourney || (window.RenderJourney = {});

  RenderJourney.init = function() {
    $(window).on('load', function() {
      setTimeout(function() {
        if ($("#error_count").html()=="0") {
          $("#no_errors").show();
        } else {
          $("#errors_count_top").html($("#error_count").html());
          $("#errors_exits").show();
        }
      }, 100);
    });
  }

}).call(this); 