(function() {

  window.Vicobas || (window.Vicobas = {});

  Vicobas.init = function() {
    $('.meeting_cadence input, .meeting_cadence select').change(function() {
      cadence = 'not_set';
      if ($('.meeting_cadence input:checked').length==1) {
        cadence = $('.meeting_cadence input:checked')[0].id
      }
      day = $('.meeting_cadence select option:selected')[0].value
      $('.meeting_cadence select').prop('disabled', (this.id=='not_set'));
      this.form.submit();
    });
  }

}).call(this); 