(function() {

  window.Alerts || (window.Alerts = {});

  Alerts.init = function() {
    $(".alert-checkbox").click(function (e) {
      Alerts.clear( $(this).data('id'), $(this).prop('checked') );
    });
  }

  Alerts.clear = function(id, clear) {
    var jqxhr = $.post("/alerts/" + id + (clear ? '/clear' : '/reset'), { _method:'put' }, function() {    });
    jqxhr.fail(function(event) {
      alert(event.responseText);
    });
  }

}).call(this); 