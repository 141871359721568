(function() {

  window.TranslationsEditor || (window.TranslationsEditor = {});

  TranslationsEditor.init = function() {
    TranslationsEditor.initSubmission();
    TranslationsEditor.initTableClick();
    TranslationsEditor.addRow();
  }

  TranslationsEditor.initSubmission = function() {
    $(".string-editor.template").keyup(function(event){
      if(event.keyCode == 13){
        locale      = $(this).find('.locale').val();
        stringName  = $(this).find('.stringName').val();
        string      = $(this).find('.string').val();
        TranslationsEditor.submit(locale, stringName, string);
      }
    });
    $(".string-editor.template .db-remove").click(function() {
      cell = $(this).closest('td');
      locale      = cell.find('.locale').val();
      stringName  = cell.find('.stringName').val();
      TranslationsEditor.delete(locale, stringName);
      return false;
    });
  }

  TranslationsEditor.initTableClick = function() {
    $(".translations tr td:not(:first-child)").click(function() {
      $(".string-editor.templated").remove();
      $(".translations .bg-warning").removeClass("bg-warning");
      cell = $(this);
      cell.addClass("bg-warning");
      partner     = $($(cell.closest('table'))[0]).data("partner-code");
      locale      = $(cell.closest('table').find('th').eq(cell.index())[0]).html().toLowerCase() + '-' + partner ;
      stringName  = $(cell.closest('tr').find('strong').eq(0)[0]).html();
      string      = cell.find('span').html();
      db          = cell.find('.db').is(":visible");
      Templater.insertAfter(cell.parent(), $(".string-editor.template"), { locale: locale, stringName: stringName, string: string });
      if (db) {
        $(".string-editor.templated .db-remove").show();
      }
      $(".string-editor.templated .string").focus();
    });
  }

  TranslationsEditor.delete = function(locale, stringName) {
    $(".string-editor.templated .string").prop("disabled", true);
    $.ajax({
      type : "DELETE",
      url :  "/remove_translations/" + locale,
      contentType: 'application/json',
      data : JSON.stringify({ string_name: stringName})
    }).success(function() {
      source = $($(".string-editor.templated")[0].previousElementSibling).find('.bg-warning');
      source.find('.db').hide();
      source.find('.string').html('');
      source.removeClass("bg-warning");
      $(".string-editor.templated").remove();
    }).fail(function(event) {
      alert(event.responseText);
    });
  }

  TranslationsEditor.submit = function(locale, stringName, string) {
    $(".string-editor.templated .string").prop("disabled", true);
    $.ajax({
      type : "POST",
      url :  "/add_translations/" + locale ,
      dataType: 'json',
      contentType: 'application/json',
      data : JSON.stringify({ string: string , string_name: stringName})
    }).success(function() {
      source = $($(".string-editor.templated")[0].previousElementSibling).find('.bg-warning');
      source.removeClass("bg-warning");
      source.find('span').html(string);
      source.find('.db').show();
      $(".string-editor.templated").remove();
    }).fail(function(event) {
      alert(event.responseText);
    });
  }

  TranslationsEditor.addRow = function() {
    $("#add_new_string").click(function() {
      new_string = $("#string_name").val().trim();
      columns = $('tr:last td').length
      if (new_string.length>0) {
        row = Templater.insertAfter($(".translations tr:first"), $(".new-string.template"), { stringName: new_string });
        template = $(row.find("td:last")[0])
        if (columns>2) {
          for(i = 2; i<columns; i++) {
            row.find('td:last').after(template.clone(true));
          }
        }
      }
      return false;
    });
  }

}).call(this); 