(function() {

  window.ClipboardHandler || (window.ClipboardHandler = {});

  ClipboardHandler.init = function() {
    
    var clipboard = new Clipboard('.clipboard');

    clipboard.on('success', function(e) {
      span = $($(e.trigger).closest('span'));
      span.find('img').hide();
      span.find('i').show();
      span.find('i').fadeOut( 1000, function() { span.find('img').show(); } );
      e.clearSelection();
      return false;
    });
  }

}).call(this); 