(function () {
  window.DatePicker || (window.DatePicker = {});

  DatePicker.init = function () {
    $('.date-range, .date-single').each(function () {
      single = $(this).hasClass('date-single');
      submit = $(this).hasClass('date-submit');
      timeRange = $(this).hasClass('date-time');
      timeFormat = timeRange ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
      $(this).daterangepicker(
        {
          singleDatePicker: single,
          showDropdowns: true,
          timePicker: timeRange,
          startDate: moment($(this).data('start'), moment.ISO_8601),
          endDate: moment($(this).data('end'), moment.ISO_8601),
          ranges: {
            [`${I18n.today}`]: [moment().startOf('day'), moment()],
            [`${I18n.yesterday}`]: [
              moment().subtract(1, 'days').startOf('day'),
              moment().subtract(1, 'days').endOf('day')
            ],
            [`${I18n.last_7_days}`]: [moment().subtract(6, 'days'), moment()],
            [`${I18n.last_30_days}`]: [moment().subtract(29, 'days'), moment()],
            [`${I18n.this_month}`]: [
              moment().startOf('month'),
              moment().endOf('month')
            ],
            [`${I18n.last_month}`]: [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month')
            ],
            [`${I18n.ever}`]: [
              window._session['startTime']
                ? moment(window._session['startTime'])
                : moment().subtract(10, 'year').startOf('month'),
              moment()
            ]
          },
          locale: {
            format: timeFormat,
            separator: ' - ',
            applyLabel: `${I18n.apply}`,
            cancelLabel: `${I18n.cancel}`,
            fromLabel: I18n.from,
            toLabel: I18n.to,
            customRangeLabel: I18n.custom,
            weekLabel: 'W',
            daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            monthNames: [
              I18n['monthname.january'] || I18n.monthname.january,
              I18n['monthname.february'] || I18n.monthname.february,
              I18n['monthname.march'] || I18n.monthname.march,
              I18n['monthname.april'] || I18n.monthname.april,
              I18n['monthname.may'] || I18n.monthname.may,
              I18n['monthname.june'] || I18n.monthname.june,
              I18n['monthname.july'] || I18n.monthname.july,
              I18n['monthname.august'] || I18n.monthname.august,
              I18n['monthname.september'] || I18n.monthname.september,
              I18n['monthname.october'] || I18n.monthname.october,
              I18n['monthname.november'] || I18n.monthname.november,
              I18n['monthname.december'] || I18n.monthname.december
            ],
            firstDay: 1
          }
        },
        function (start, end, label) {
          if (submit) {
            search = window.location.search;

            var startInMillis = new Date(start.toString()).getTime();
            var endInMillis = new Date(end.toString()).getTime();
            var timeZoneName = window._session.timeZone;
            if (timeZoneName == undefined) {
              var diff = 0;
            } else {
              var partnerDate = new Date(
                new Date(startInMillis).toLocaleString('en-US', {
                  timeZone: timeZoneName
                })
              );
              var diff = startInMillis - partnerDate.getTime();
            }
            var partnerstartDate = new Date(startInMillis + diff);
            var partnerendDate = new Date(endInMillis + diff);

            //if (single) { start = end; }
            search = YabxUtils.updateQueryStringParameter(
              search,
              '_start',
              partnerstartDate.toJSON()
            );
            search = YabxUtils.updateQueryStringParameter(
              search,
              '_end',
              partnerendDate.toJSON()
            );
            search = YabxUtils.updateQueryStringParameter(search, 'page', 1);
            window.location.search = search;
          } else {
            $(this).trigger('date-change', start, end, label);
          }
        }
      );
    });
  };
}.call(this));
